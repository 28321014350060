import { render, staticRenderFns } from "./MenuLists.vue?vue&type=template&id=4a6e2740&scoped=true"
import script from "./MenuLists.vue?vue&type=script&lang=js"
export * from "./MenuLists.vue?vue&type=script&lang=js"
import style0 from "./MenuLists.vue?vue&type=style&index=0&id=4a6e2740&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a6e2740",
  null
  
)

export default component.exports